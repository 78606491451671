<template>
  <section class="tooltip-page">
    <Tooltip :text="'Ваш текст тултипа'" />
    <div class="tooltip-page__description">
      <h2>Пропсы:</h2>
      <ul>
        <li>text - текст тултипа</li>
      </ul>
    </div>
  </section>
</template>

<script>
import Tooltip from "@/modules/UiKit/components/Tooltip/Tooltip";
export default {
  name: "TooltipPage",
  mixins: [],
  props: {},
  components: { Tooltip },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.tooltip-page {
  position: relative;
  padding-top: 16px;
}
</style>
